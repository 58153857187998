import styled from "styled-components";
import settingsStyled from "../../../../Assets/styled/settings.styled";

export const FormMaskStyled = styled.div`
  width: 100%;

  .ant-form-item-has-error {
    input {
      border-color: ${settingsStyled.errorRed};
      outline: none;

      &:focus {
        border: solid 1px ${settingsStyled.errorRed};
      }

      &:hover {
        border: solid 1px ${settingsStyled.errorRed};
      }
    }
  }

  .formMask {
    &__input {
      margin-top: 6px;
      height: ${settingsStyled.formHeight};
      border-radius: ${settingsStyled.formRadius};
      border: solid 1px ${settingsStyled.formBorder};
      background-color: ${settingsStyled.formBg};
      padding: 0 21px;
      color: ${settingsStyled.formFontColor};
      line-height: ${settingsStyled.formHeight};
      font-size: ${settingsStyled.formFontSize};
      outline: none;
      width: 100%;
      &:focus {
        border: solid 1px ${settingsStyled.formBorderFocus};
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
      }

      &:hover {
        border: solid 1px ${settingsStyled.formBorderFocus};
      }
    }
  }
`;
