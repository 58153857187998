import styled from "styled-components";
import settingsStyled from "../../../../Assets/styled/settings.styled";

export const FormElementLabelStyled = styled.span`
  font-size: ${settingsStyled.formlabelFontSize};
  color: ${settingsStyled.formLabelColor};
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.58px;
  display: block;

  &.required {
    &:after {
      content: " *";
      color: ${settingsStyled.errorRed};
    }
  }
`;
