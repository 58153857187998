import styled from "styled-components";
import settingsStyled from "../../../../Assets/styled/settings.styled";

export const FormDatePickerStyled = styled.div`
  width: 280px;
  min-width: 140px;

  .ant {
    &-picker {
      ${settingsStyled.displayCenter}

      &-suffix,
      &-clear {
        svg {
          font-size: 15px;
        }
      }

      &-focused,
      &:hover {
        box-shadow: none;
      }
    }
  }

  .formDatePicker {
    &__datePicker {
      display: block;
      height: ${settingsStyled.formHeight};
      margin-top: 6px;
      border-radius: ${settingsStyled.formRadius};
      border: solid 1px ${settingsStyled.formBorder};
      background-color: ${settingsStyled.formBg} !important;
      padding: 4px 21px !important;

      &:hover {
        border: solid 1px ${settingsStyled.formBorderFocus};
      }
      .anticon {
        font-size: 22px;
      }
    }
  }

  .ant-picker-input {
    margin-top: 8px;
  }
  input {
    color: ${settingsStyled.formFontColor};
    font-size: ${settingsStyled.formFontSize};
    height: 24px;
    line-height: ${settingsStyled.formHeight};
  }
`;
