import styled from "styled-components";
import settingsStyled from "../../../../Assets/styled/settings.styled";

export const FormRadioGroupStyled = styled.div`
  max-width: 100%;
  .formRadioGroup {
    &-item {
      display: block;
      padding-top: 14px;
    }
    &__radioGroup {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      .ant-radio-wrapper {
        line-height: 1.44;
        font-weight: 300;
        font-size: ${settingsStyled.formFontSize};
        white-space: normal;
        ${settingsStyled.displayCenter}
        span {
          color: #343434;
        }

        .ant-radio {
          height: 18px;
          &:hover .ant-radio-inner {
            border-color: ${settingsStyled.formRadioselectBg};
          }
          &-inner {
            width: 24px;
            height: 24px;
            background-color: ${settingsStyled.formBg};
            &:after {
              background-color: ${settingsStyled.formRadioselectBg};
              width: 24px;
              height: 24px;
              margin-top: -12px;
              margin-left: -12px;
            }
          }
          &-checked {
            .ant-radio-inner {
              border-color: ${settingsStyled.formRadioselectBg};
            }
          }
        }

        &-checked {
          .formRadioGroup-item {
            color: #1c3b84;
            font-weight: 400;
          }
        }
      }
    }
  }

  /* alt alta string değerler adetli */
  &.formRadioGroup--stringButton {
    .formRadioGroup {
      &-item {
        padding-top: 0;
        font-weight: normal;
      }
      &-count {
        min-width: 40px;
        border-radius: 13px;
        background-color: #f5f8f8;
        height: 26px;
        ${settingsStyled.displayCenterCenter}
        padding: 0 5px;
        font-size: 13px;
        font-weight: 500;
        color: #a0b4bc !important;
      }
    }

    .ant-radio {
      display: none;
      &-group {
        flex-direction: column;
      }

      &-wrapper {
        margin-bottom: 12px;
        & > span:nth-child(2) {
          padding: 0;
          ${settingsStyled.displayBetweenCenter}
          width: 100%;
        }
        &-checked {
          .formRadioGroup {
            &-item {
              color: #ff002b;
              font-weight: 600;
            }
            &-count {
              background-color: #ff002b;
              color: #fff !important;
            }
          }
        }
      }
    }
  }

  .formElementLabel {
    margin-bottom: 30px;
  }

  .formTextInput-container__label {
    display: none;
  }
`;
