import React, { memo, useCallback } from "react";
import { Form, InputNumber } from "antd";
import FormElementLabel from "./formElementLabel";
import PropTypes from "prop-types";
import getWordControl from "../../utils/language/getWordControl";
import { FormInputNumberStyled } from "./styled/formInputNumber.styled";

//<FormInputNumber
//  label="lbl"
//  propName="title6"
//  className="clas"
//  requiredStatus={false}
//  placeholder="lbl"
//  errorMessage=""
//  disabled={true}
//  max={99999999}
//  min={0}
//  numberInputOnBlur={func}
//  numberInputOnChange={func}
//  type="password"
//  maxLength={6} // harf sayısı
///>

const FormInputNumber = memo(
  ({
    propName,
    label,
    requiredStatus,
    placeholder,
    className,
    errorMessage,
    max,
    disabled,
    min,
    numberInputOnBlur,
    numberInputOnChange,
    type,
    maxLength,
    minLength,
  }) => {
    const { GetWord } = getWordControl();
    const onBlur = useCallback(
      (e) => {
        if (numberInputOnBlur) {
          numberInputOnBlur(e.target.value, propName);
        }
      },
      [numberInputOnBlur, propName]
    );

    const onChange = useCallback(
      (value) => {
        if (numberInputOnChange) {
          numberInputOnChange(value, propName);
        }
      },
      [propName, numberInputOnChange]
    );

    return (
      <FormInputNumberStyled className={"formInputNumber " + (className ? className : "")}>
        <FormElementLabel labelName={label} requiredStatus={requiredStatus} />
        <Form.Item
          name={propName}
          rules={[
            {
              required: requiredStatus,
              message: errorMessage
                ? GetWord(errorMessage)
                : GetWord("pleaseFillRequiredFields"),
            },
          ]}
        >
          <InputNumber
            className={propName + " formInputNumber__input "}
            placeholder={placeholder ? GetWord(placeholder) : ""}
            disabled={disabled}
            max={max ? max : 999999999999999999999999999999999}
            min={min ? min : 0}
            onBlur={onBlur}
            onChange={onChange}
            maxLength={maxLength}
            minLength={minLength}
            type={type}
          />
        </Form.Item>
      </FormInputNumberStyled>
    );
  }
);

FormInputNumber.propTypes = {
  propName: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  requiredStatus: PropTypes.bool,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  numberInputOnBlur: PropTypes.func,
  numberInputOnChange: PropTypes.func,
};

export default FormInputNumber;
