import React, { memo, useCallback, useMemo, useState } from "react";
import { Form, Input } from "antd";
import FormElementLabel from "./formElementLabel";
import PropTypes from "prop-types";
import getWordControl from "../../utils/language/getWordControl";
import { FormTextInputStyled } from "./styled/formTextInput.styled";

//<FormTextInput
//  label="lbl"
//  propName="title6"
//  className="clas"
//  type=""
//  requiredStatus={false}
//  placeholder="lbl"
//  errorMessage=""
//  disabled={true}
//  textInputOnBlur={func}
//  textInputOnChange={func}
//  autoFocus={false}
//  maxLength={50}
///>

const FormTextInput = memo(
  ({
    propName,
    label,
    requiredStatus,
    type,
    placeholder,
    className,
    errorMessage,
    disabled,
    textInputOnBlur,
    textInputOnChange,
    autoFocus,
    maxLength,
    onSelect,
  }) => {
    const [focusedOut, setFocusedOut] = useState(false);
    const { GetWord } = getWordControl();

    const rulesControl = useMemo(() => {
      if (type === "email") {
        return [
          {
            type,
            required: requiredStatus,
            message: errorMessage || "Lütfen email adresinizi doğru doldurun.",
          },
        ];
      } else {
        return [
          {
            // type: type ? type : "text", // burayı time dan dolayı kapattım
            required: requiredStatus,
            message: errorMessage
              ? GetWord(errorMessage)
              : GetWord("pleaseFillRequiredFields"),
          },
        ];
      }
    }, [errorMessage, requiredStatus, focusedOut, type]);

    const onBlur = useCallback(
      (e) => {
        if (type === "email") {
          setFocusedOut(true);
        }

        if (textInputOnBlur) {
          textInputOnBlur(e.target.value, propName);
        }
      },
      [propName, textInputOnBlur, type]
    );

    const onChange = useCallback(
      (e) => {
        if (textInputOnChange) {
          textInputOnChange(e.target.value, propName);
        }
      },
      [propName, textInputOnChange]
    );

    return (
      <FormTextInputStyled className={"formTextInput " + (className ? className : "")}>
        <FormElementLabel labelName={label} requiredStatus={requiredStatus} />

        <div className="formTextInput-inner">
          <Form.Item name={propName} rules={rulesControl}>
            <Input
              className={propName + " formTextInput__input "}
              type={type === "email" ? undefined : type || "text"} // burayı time dan dolayı type bağladım
              onBlur={onBlur}
              onFocus={() => {
                setFocusedOut(false);
              }}
              placeholder={placeholder ? GetWord(placeholder) : ""}
              disabled={disabled}
              onChange={onChange}
              autoFocus={autoFocus}
              maxLength={maxLength}
              onSelect={onSelect}
            />
          </Form.Item>
        </div>
      </FormTextInputStyled>
    );
  }
);

FormTextInput.propTypes = {
  propName: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  requiredStatus: PropTypes.bool,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  textInputOnBlur: PropTypes.func,
  textInputOnChange: PropTypes.func,
  type: PropTypes.string,
  autoFocus: PropTypes.bool,
  maxLength: PropTypes.number,
};

export default FormTextInput;
