import React, { memo, useCallback, useMemo, useState } from "react";
import { Form, Checkbox } from "antd";
import PropTypes from "prop-types";
import getWordControl from "../../utils/language/getWordControl";
import FormElement from "./";
import { FormCheckBoxStyled } from "./styled/formCheckBox.styled";
import FormElementLabel from "./formElementLabel";
import ModalGeneral from "../modal/modalGeneral";

// import ModalPrimary from "../modal";
// fristvalue form üzerinden verince otomatik tikliyor

// <FormCheckBox
// propName="rememberMe"
// label="Remember Me"
// requiredStatus={true}
// errorMessage=""
// className=""
// disabled={false}
// checkBoxOnChange={func}
// contractText={"text"}
// contractActive={false}
// optionsList = // grup için
// labelGetwordClose
// [{
//   { id: "other", name: "other" }
// }]
// />

const FormCheckBox = memo((props) => {
  const {
    secondLabel,
    propName,
    label,
    requiredStatus,
    errorMessage,
    disabled,
    checkBoxOnChange,
    className,
    contractActive,
    contractText,
    labelGetwordClose,
    optionsList,
    filterActive,
    children,
  } = props;
  const [contractStatus, setContractStatus] = useState(false);
  const [searchText, setSearchText] = useState(null);

  const { GetWord } = getWordControl();

  const onChange = useCallback(
    (e) => {
      if (checkBoxOnChange) {
        checkBoxOnChange(optionsList ? e : e.target.checked, propName);
      }
    },
    [propName, checkBoxOnChange, optionsList]
  );

  const checkTextClick = useCallback(() => {
    if (contractActive) {
      setContractStatus((prev) => !prev);
    }
  }, [contractActive]);

  const checkBoxGrupControl = useMemo(() => {
    return (
      <Checkbox.Group
        disabled={disabled}
        className="formCheckBox-group"
        onChange={onChange}
      >
        {optionsList?.map((item, i) => {
          const { id, name } = item;
          return (
            <Checkbox
              key={i}
              value={id}
              style={{
                display: searchText
                  ? !item.name
                      ?.toLocaleLowerCase()
                      ?.includes(searchText.toLocaleLowerCase()) && "none"
                  : undefined,
              }}
            >
              <span className="formCheckBox__label">{name}</span>
            </Checkbox>
          );
        })}
      </Checkbox.Group>
    );
  }, [disabled, onChange, optionsList, searchText]);

  const searchOnChange = useCallback((value, propName, fullData) => {
    setSearchText(value);
  }, []);

  return (
    <FormCheckBoxStyled className={"formCheckBox " + (className || "")}>
      {label && optionsList && (
        <FormElementLabel
          labelName={label}
          requiredStatus={requiredStatus}
          labelGetwordClose={labelGetwordClose}
        />
      )}

      {filterActive && optionsList?.length > 10 && (
        <div className="formCheckBox-search">
          <FormElement
            formElementName="textInput"
            propName={propName + "Search"}
            placeholder={
              (labelGetwordClose ? label : GetWord(label)) +
              " " +
              GetWord("lblSearch")
            }
            formOnChange={searchOnChange}
          />

          <Icon name="icon-search1" />
        </div>
      )}

      {/* multi select varsa */}
      {optionsList ? (
        <Form.Item
          name={propName}
          rules={
            requiredStatus
              ? [
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(
                            errorMessage
                              ? GetWord(errorMessage)
                              : GetWord("pleaseFillRequiredFields")
                          ),
                  },
                ]
              : null
          }
        >
          {checkBoxGrupControl}
        </Form.Item>
      ) : (
        <Form.Item
          name={propName}
          valuePropName="checked"
          rules={
            requiredStatus
              ? [
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(
                            errorMessage
                              ? GetWord(errorMessage)
                              : GetWord("pleaseFillRequiredFields")
                          ),
                  },
                ]
              : null
          }
        >
          <Checkbox disabled={disabled} onChange={onChange}>
            {label ? (
              <span
                className={
                  "formCheckBox__label" +
                  (requiredStatus ? " required " : "") +
                  (contractActive ? " formCheckBox-contractActive" : "")
                }
                onClick={checkTextClick}
              >
                {labelGetwordClose
                  ? label
                  : secondLabel
                  ?(<><span>{secondLabel}</span> <>{GetWord(label)}</></>)
                  : GetWord(label)}
              </span>
            ) : (
              children && children
            )}
          </Checkbox>
        </Form.Item>
      )}

      {/* kontrat */}
      {contractActive && contractStatus && (
        <ModalGeneral
          modalCloseControl={checkTextClick}
          whiteModalActive={true}
          modalStatus={true}
          closeIconActive={true}
        >
          <div className="formCheckBox-modal">
            <div
              className="formCheckBox-modal-container"
              dangerouslySetInnerHTML={{ __html: contractText }}
            ></div>
          </div>
        </ModalGeneral>
      )}
    </FormCheckBoxStyled>
  );
});

FormCheckBox.propTypes = {
  propName: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  requiredStatus: PropTypes.bool,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  checkBoxOnChange: PropTypes.func,
  contractActive: PropTypes.bool,
  contractText: PropTypes.node,
  labelGetwordClose: PropTypes.bool,
};

export default FormCheckBox;
