import styled from "styled-components";
import settingsStyled from "../../../../Assets/styled/settings.styled";

export const FormInputNumberStyled = styled.span`
  width: 100%;

  .ant-input-number {
    &-disabled {
      background-color: #f5f5f5;
    }

    &-handler-wrap {
      background: transparent !important;
      border: none !important;
      width: 100% !important;
      display: none;
    }

    &-focused {
      border: solid 1px ${settingsStyled.formBorderFocus};
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08) !important;
    }
  }

  .formInputNumber {
    &__input {
      width: 100%;
      margin-top: 6px;
      border-radius: ${settingsStyled.formRadius};
      border: solid 1px ${settingsStyled.formBorder};
      min-height: 50px;

      input {
        height: ${settingsStyled.formHeight};
        color: ${settingsStyled.formFontColor};
        line-height: ${settingsStyled.formHeight};
        font-size: ${settingsStyled.formFontSize};
        outline: none !important;
        -moz-appearance: textfield;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      &:hover {
        border: solid 1px ${settingsStyled.formBorderFocus};
      }
    }
  }
`;
