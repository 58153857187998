import styled from "styled-components";
import settingsStyled from "../../../../Assets/styled/settings.styled";

export const FormDateAndTimePickerStyled = styled.div`
  .formDateAndTimePicker {
    &-container {
      ${settingsStyled.displayBetween}

      .ant-picker {
        width: 100% !important;
      }

      .formDatePicker {
        width: calc(100% - 148px);
        max-width: 100%;

        @media ${settingsStyled.m767} {
          width: calc(50% - 10px);
        }
      }

      .formDatePicker-container__datePicker {
        width: 100%;
      }

      .formTimePicker {
        margin-left: 20px;
        min-width: 128px;

        @media ${settingsStyled.m767} {
          min-width: calc(50% - 10px);
          width: calc(50% - 10px);
        }
      }
    }
  }
`;
