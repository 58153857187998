import React, { memo } from "react";
import { FormElementLabelStyled } from "./styled/formElementLabel.styled";
import PropTypes from "prop-types";
import getWordControl from "../../utils/language/getWordControl";

//<FormElementLabel
//   labelName="lbl"
//   requiredStatus={false}
//   className=""
//   labelGetwordClose={false}
// />;

const FormElementLabel = memo(
  ({ labelName, requiredStatus, className, labelGetwordClose }) => {
    const { GetWord } = getWordControl();
    return labelName ? (
      <FormElementLabelStyled
        className={
          "formElementLabel " +
          (requiredStatus ? " required " : "") +
          (className ? className : "")
        }
      >
        {labelGetwordClose ? labelName : GetWord(labelName)}
      </FormElementLabelStyled>
    ) : null;
  }
);

FormElementLabel.propTypes = {
  labelName: PropTypes.string,
  className: PropTypes.string,
  requiredStatus: PropTypes.bool,
  labelGetwordClose: PropTypes.bool,
};

export default FormElementLabel;
