import React, { memo, useCallback } from "react";
import { FormDateAndTimePickerStyled } from "./styled/formDateAndTimePicker.styled";
import FormDatePicker from "./formDatePicker";
import FormTimePicker from "./formTimePicker";
import FormElementLabel from "./formElementLabel";
import PropTypes from "prop-types";

//<FormDateAndTimePicker
//   label="lbl"
//   dateAndTimePickerOnChange={func}
//   dateData={{
//     propName: "",
//     className: "",
//     requiredStatus: true,
//     placeholder: "",
//     errorMessage: "",
//     disabled: false,
//     disablePastTime: false,
//   }}
//   timeData={{
//     propName: "name1",
//     className: "",
//     requiredStatus: true,
//     placeholder: "",
//     errorMessage: "",
//     disabled: false,
//     format: "HH:mm",
//   }}
// />

const FormDateAndTimePicker = memo(
  ({ timeData, label, dateData, dateAndTimePickerOnChange }) => {
    const onChange = useCallback(
      (value, prop) => {
        if (dateAndTimePickerOnChange) {
          dateAndTimePickerOnChange(value, prop);
        }
      },
      [dateAndTimePickerOnChange]
    );
    return (
      <FormDateAndTimePickerStyled className="formDateAndTimePicker">
        <FormElementLabel
          labelName={label}
          requiredStatus={dateData?.requiredStatus || timeData?.requiredStatus}
        />
        <div className="formDateAndTimePicker-container">
          {dateData && (
            <FormDatePicker
              disablePastTime={dateData.disablePastTime}
              propName={dateData.propName}
              placeholder={dateData.placeholder}
              className={dateData.className}
              requiredStatus={dateData.requiredStatus}
              errorMessage={dateData.errorMessage}
              disabled={dateData.disabled}
              datePickerOnChange={onChange}
            />
          )}
          {timeData && (
            <FormTimePicker
              propName={timeData.propName}
              requiredStatus={timeData.requiredStatus}
              errorMessage={timeData.errorMessage}
              className={timeData.className}
              placeholder={timeData.placeholder}
              disabled={timeData.disabled}
              timePickerOnChange={onChange}
              format={timeData.format}
            />
          )}
        </div>
      </FormDateAndTimePickerStyled>
    );
  }
);

FormDateAndTimePicker.propTypes = {
  timeData: PropTypes.object,
  label: PropTypes.string,
  dateData: PropTypes.object,
  dateAndTimePickerOnChange: PropTypes.func,
};

export default FormDateAndTimePicker;
