import React, { memo, useCallback, useMemo } from "react";
import { Form, Input } from "antd";
import FormElementLabel from "./formElementLabel";
import PropTypes from "prop-types";
import { FormTextInputStyled } from "./styled/formTextInput.styled";
import getWordControl from "../../utils/language/getWordControl";

//<FormPasswordInput
//  label="İstenilen Vade Tarihi"
//  propName="title6"
//  className="clas"
//  requiredStatus={false}
//  placeholder="placeholder"
//  errorMessage=""
//  passwordInputOnBlur={func}
//  passwordInputOnChange={func},
//  maxLength={50}
//  dependencies="password" // şifre tekrarı için
//  pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
///>

const FormPasswordInput = memo(
  ({
    propName,
    label,
    requiredStatus,
    placeholder,
    className,
    errorMessage,
    passwordInputOnBlur,
    passwordInputOnChange,
    maxLength,
    dependencies,
    pattern,
  }) => {
    const { GetWord } = getWordControl();

    const onBlur = useCallback(
      (e) => {
        if (passwordInputOnBlur) {
          passwordInputOnBlur(e.target.value, propName);
        }
      },
      [propName, passwordInputOnBlur]
    );

    const onChange = useCallback(
      (e) => {
        if (passwordInputOnChange) {
          passwordInputOnChange(e.target.value, propName);
        }
      },
      [propName, passwordInputOnChange]
    );

    const rules = useMemo(() => {
      if (dependencies) {
        return [
          {
            required: requiredStatus,
            message: "",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              let patternControl = true;

              if (pattern) {
                const validPassword = new RegExp(pattern);
                patternControl = validPassword.test(value);
              }

              if (!patternControl) {
                return Promise.reject(
                  new Error(GetWord("errPasswordCharacters"))
                );
              } else if (!value || getFieldValue(dependencies) === value) {
                return Promise.resolve();
              } else {
                return Promise.reject(
                  new Error(GetWord("errPasswordsCouldNotBeMatched"))
                );
              }
            },
          }),
        ];
      } else if (pattern) {
        return [
          {
            required: requiredStatus,
            message: "",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              const validPassword = new RegExp(pattern);
              const patternControl = validPassword.test(value);

              if (!patternControl) {
                return Promise.reject(
                  new Error(GetWord("errPasswordCharacters"))
                );
              } else if (value) {
                return Promise.resolve();
              } else {
                return Promise.reject(new Error(GetWord("pleaseFillRequiredFields")));
              }
            },
          }),
        ];
      } else {
        return [
          {
            required: requiredStatus,
            message: errorMessage
              ? GetWord(errorMessage)
              : GetWord("pleaseFillRequiredFields"),
          },
        ];
      }
    }, [errorMessage, requiredStatus, pattern, dependencies]);

    return (
      <FormTextInputStyled
        className={"formTextInput formTextInput--password " + (className || "")}
      >
        <FormElementLabel labelName={label} requiredStatus={requiredStatus} />
        <Form.Item
          name={propName}
          dependencies={dependencies ? [dependencies] : undefined}
          rules={rules}
        >
          <Input.Password
            className={propName + " formTextInput__input "}
            onChange={onChange}
            placeholder={placeholder ? GetWord(placeholder) : ""}
            autoComplete="new-password"
            onBlur={onBlur}
            maxLength={maxLength}
          />
        </Form.Item>
      </FormTextInputStyled>
    );
  }
);

FormPasswordInput.propTypes = {
  propName: PropTypes.string.isRequired,
  className: PropTypes.string,
  pattern: PropTypes.string,
  label: PropTypes.string,
  requiredStatus: PropTypes.bool,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  maxLength: PropTypes.number,
  passwordInputOnBlur: PropTypes.func,
  passwordInputOnChange: PropTypes.func,
};
export default FormPasswordInput;
