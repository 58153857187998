import React, { memo, useCallback } from "react";
import { Form, DatePicker } from "antd";
import moment from "moment";
import { antDesignLanguageLocale } from "../../utils/language/antDesignLanguage";
import FormElementLabel from "./formElementLabel";
import PropTypes from "prop-types";
import getWordControl from "../../utils/language/getWordControl";
import { FormDatePickerStyled } from "./styled/formDatePicker.styled";

//<FormDatePicker
// label="Date"
// disablePastTime={true}
// propName="date"
// placeholder={"date"}
// className={"date"}
// requiredStatus={true}
// errorMessage="errPleaseSelect"
// disabled={true}
// datePickerOnChange={func}
// picker="" // week / month / quarter / year
// allowClear={false}
///>

const FormDatePicker = memo(
  ({
    propName,
    label,
    requiredStatus,
    placeholder,
    className,
    errorMessage,
    disablePastTime,
    disabled,
    datePickerOnChange,
    picker,
    allowClear,
  }) => {
    const { GetWord } = getWordControl();
    // eksi tarih seçmeyi engelliyor.
    const disabledDate = (current) => {
      return current && current < moment().startOf("day");
    };

    const onChange = useCallback(
      (value) => {
        if (datePickerOnChange) {
          datePickerOnChange(value, propName);
        }
      },
      [propName, datePickerOnChange]
    );

    return (
      <FormDatePickerStyled className={"formDatePicker " + (className ? className : "")}>
        <FormElementLabel labelName={label} requiredStatus={requiredStatus} />
        <Form.Item
          name={propName}
          rules={[
            {
              required: requiredStatus,
              message: errorMessage
                ? GetWord(errorMessage)
                : GetWord("pleaseFillRequiredFields"),
            },
          ]}
        >
          <DatePicker
            format={picker === "month" ? "MM/YYYY" : "DD/MM/YYYY"}
            className={propName + " formDatePicker__datePicker "}
            disabledDate={disablePastTime && disabledDate}
            disabled={disabled}
            locale={antDesignLanguageLocale()}
            placeholder={placeholder ? GetWord(placeholder) : " "}
            onChange={onChange}
            picker={picker}
            allowClear={allowClear}
          />
        </Form.Item>
      </FormDatePickerStyled>
    );
  }
);

FormDatePicker.propTypes = {
  propName: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  requiredStatus: PropTypes.bool,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  disablePastTime: PropTypes.bool,
  datePickerOnChange: PropTypes.func,
  picker: PropTypes.string,
  allowClear: PropTypes.bool,
};

export default FormDatePicker;
