import localeTR from "antd/lib/date-picker/locale/tr_TR.js";
import moment from "moment";
import "moment/locale/tr";
import { getLanguageID } from "../common";
import { Constants } from "../constants";

//header üzerindeki style belirliyor
function antDesignLanguageCode() {
  const newLanguageID =
    (typeof window !== "undefined" && getLanguageID()) ||
    Constants.DEFAULT_LANGUAGE_ID;
  switch (newLanguageID) {
    case "1":
    case 1:
      return "tr";
    default:
      return "";
  }
}

//header üzerindeki style belirliyor
export function antDesignLanguageLocale() {
  const newLanguageID =
    (typeof window !== "undefined" && getLanguageID()) ||
    Constants.DEFAULT_LANGUAGE_ID;

  switch (newLanguageID) {
    case "1":
    case 1:
      return localeTR;
    default:
      return "";
  }
}

moment.locale(antDesignLanguageCode());
