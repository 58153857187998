import React, { memo, useCallback, useMemo } from "react";
import { Form, TimePicker } from "antd";
import { antDesignLanguageLocale } from "../../utils/language/antDesignLanguage";
import FormElementLabel from "./formElementLabel";
import PropTypes from "prop-types";
import getWordControl from "../../utils/language/getWordControl";
import { FormTimePickerStyled } from "./styled/formTimePicker.styled";

//<FormTimePicker
//label=""
//propName=""
//requiredStatus={true}
//errorMessage="errPleaseSelect"
//format="HH:mm" // zorunlu değil otomatik alıyor
//className=""
//placeholder=""
//disabled={false}
//timePickerOnChange={func}
///>

const FormTimePicker = memo(
  ({
    propName,
    label,
    requiredStatus,
    className,
    errorMessage,
    format,
    placeholder,
    disabled,
    timePickerOnChange,
  }) => {
    const { GetWord } = getWordControl();
    const onChange = useCallback(
      (e) => {
        if (timePickerOnChange) {
          timePickerOnChange(e, propName);
        }
      },
      [propName, timePickerOnChange]
    );

    const antDesignLanguage = useMemo(() => {
      return antDesignLanguageLocale();
    }, []);

    return (
      <FormTimePickerStyled
        className={"formTimePicker " + (className ? className : "")}
      >
        <FormElementLabel labelName={label} requiredStatus={requiredStatus} />
        <Form.Item
          name={propName}
          rules={[
            {
              type: "object",
              required: requiredStatus,
              message: errorMessage
                ? GetWord(errorMessage)
                : GetWord("pleaseFillRequiredFields"),
            },
          ]}
        >
          <TimePicker
            locale={antDesignLanguage}
            className="formTimePicker__datePicker"
            format={format ? format : "HH:mm"}
            placeholder={placeholder ? GetWord(placeholder) : " "}
            disabled={disabled}
            showNow={true}
            onChange={onChange}
          />
        </Form.Item>
      </FormTimePickerStyled>
    );
  }
);

FormTimePicker.propTypes = {
  propName: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  requiredStatus: PropTypes.bool,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  format: PropTypes.string,
  timePickerOnChange: PropTypes.func,
};

export default FormTimePicker;
