import React, { memo, useMemo } from "react";
import { Form, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import FormElementLabel from "./formElementLabel";
import FormElement from "./";
import Icon from "../icons/icon";
import PropTypes from "prop-types";
import getWordControl from "../../utils/language/getWordControl";
import { FormListInputsStyled } from "./styled/formListInputs.styled";

// const formList = useMemo(() => {
//   return [
//     {
//       formElementName: "textInput",
//       label: "lblName",
//       propName: "name",
//       className: "",
//       requiredStatus: true,
//       placeholder: "",
//       errorMessage: "",
//       disabled: false,
//       type: "",
//       formOnBlur,
//       formOnChange,
//     },
//     {
//       formElementName: "inputNumber",
//       label: "lblNumber",
//       propName: "number",
//       className: "",
//       requiredStatus: true,
//       placeholder: "",
//       errorMessage: "",
//       disabled: false,
//       formOnBlur,
//       formOnChange,
//       max: 99999,
//       min: 0,
//     },
//     {
//       formElementName: "datePicker",
//       label: "lblDatePicker",
//       propName: "datePicker2",
//       className: "",
//       requiredStatus: true,
//       placeholder: "",
//       errorMessage: "",
//       disabled: false,
//       formOnChange,
//       disablePastTime: false,
//     },
//   ];
// }, [formOnChange, formOnBlur]);

//  <FormListInputs
//   label="lbl"
//   propName=""
//   className=""
//   formList={[]}
//   buttonName="lbl"
//   deleteItemChange={func} // hangi item delete olmuşsa onun sıralmasındaki key dönüyor
// />

// const deleteItemChange = useCallback((id) => {
//   if (travelRequestData?.guests?.length > 0 && travelRequestData.guests[id]) {
//     setGuestsDeleteList((prev) => {
//       return [
//         ...prev,
//         {
//           ...travelRequestData.guests[id],
//           StatusID: 2,
//         },
//       ];
//     });
//   }

//   // eslint-disable-next-line react-hooks/exhaustive-deps
// }, []);

const FormListInputs = memo(
  ({ propName, label, className, formList, buttonName, deleteItemChange }) => {
    const { GetWord } = getWordControl();
    const componentControl = useMemo(() => {
      return (
        <Form.List name={propName}>
          {(fields, { add, remove }) => {
            return (
              <div className="formListInputs-list">
                {fields.map((field, i) => {
                  return (
                    <div className="formListInputs-list-item" key={field.key}>
                      {formList.map((element, index) => {
                        const {
                          formElementName,
                          formOnChange,
                          label,
                          propName,
                          className,
                          requiredStatus,
                          placeholder,
                          errorMessage,
                          disabled,
                          type,
                          formOnBlur,
                          max,
                          min,
                          disablePastTime,
                        } = element;

                        if (formElementName === "textInput") {
                          return (
                            <FormElement
                              key={index}
                              formElementName={formElementName}
                              label={label}
                              propName={[field.name, propName]}
                              className={className}
                              requiredStatus={requiredStatus}
                              placeholder={placeholder}
                              errorMessage={errorMessage}
                              disabled={disabled}
                              type={type}
                              formOnBlur={formOnBlur}
                              formOnChange={formOnChange}
                            />
                          );
                        } else if (formElementName === "inputNumber") {
                          return (
                            <FormElement
                              key={index}
                              formElementName={formElementName}
                              label={label}
                              propName={[field.name, propName]}
                              className={className}
                              requiredStatus={requiredStatus}
                              placeholder={placeholder}
                              errorMessage={errorMessage}
                              disabled={disabled}
                              max={max}
                              min={min}
                              formOnBlur={formOnBlur}
                              formOnChange={formOnChange}
                            />
                          );
                        } else if (formElementName === "datePicker") {
                          return (
                            <FormElement
                              key={index}
                              formElementName={formElementName}
                              label={label}
                              propName={[field.name, propName]}
                              className={className}
                              requiredStatus={requiredStatus}
                              placeholder={placeholder}
                              errorMessage={errorMessage}
                              disabled={disabled}
                              disablePastTime={disablePastTime}
                              formOnChange={formOnChange}
                            />
                          );
                        } else {
                          return null;
                        }
                      })}

                      <div
                        className="formListInputs-list-item-close"
                        onClick={() => {
                          remove(field.name);
                          if (deleteItemChange) {
                            deleteItemChange(field.key);
                          }
                        }}
                      >
                        <Icon name="icon-close" />
                      </div>
                    </div>
                  );
                })}

                {buttonName && (
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      block
                    >
                      <PlusOutlined /> {GetWord(buttonName)}
                    </Button>
                  </Form.Item>
                )}
              </div>
            );
          }}
        </Form.List>
      );
    }, [formList, buttonName, propName, deleteItemChange]);

    return (
      <FormListInputsStyled className={"formListInputs " + (className ? className : "")}>
        <FormElementLabel labelName={label} className="formListInputs--label" />

        {componentControl}
      </FormListInputsStyled>
    );
  }
);

FormListInputs.propTypes = {
  propName: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  formList: PropTypes.array,
  buttonName: PropTypes.string,
  deleteItemChange: PropTypes.func,
};

export default FormListInputs;
