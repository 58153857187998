import styled from "styled-components";
import settingsStyled from "../../../../Assets/styled/settings.styled";

export const FormSelectBoxStyled = styled.div`
  width: 100%;

  /* antd */
  .ant {
    &-select-clear {
      width: 15px;
      height: 15px;
      z-index: 100;

      svg {
        font-size: 13px;
      }
    }
    /* other altındaki boşluğu alıyor */
    &-row:nth-child(3) {
      margin-bottom: 0;
    }

    &-form-item-control-input {
      min-height: 0;
    }
    &-select-selection-placeholder {
      line-height: ${settingsStyled.formHeight} !important;
    }

    &-select-selector {
      min-height: ${settingsStyled.formHeight};
    }
  }

  .formTextInput {
    width: 100% !important;
  }

  .formSelectBox {
    &__select {
      margin-top: 6px;
      border-radius: ${settingsStyled.formRadius};
      background-color: ${settingsStyled.formBg} !important;
      color: ${settingsStyled.formFontColor};
      line-height: 1.44;
      font-weight: 500;
      transition: all 0.4s ease;
      font-size: ${settingsStyled.formFontSize};

      &.ant-select-focused {
        .ant-select-selector {
          border: solid 1px ${settingsStyled.formBorderFocus} !important;
        }
      }

      &:hover {
        .ant-select-selector {
          border: solid 1px ${settingsStyled.formBorderFocus} !important;
        }
      }

      .ant-select {
        &-arrow {
          right: 12px;
          top: calc(${settingsStyled.formHeight} / 2);

          svg {
            font-size: 14px;
          }
        }

        &-selection {
          &-search-input {
            height: 100% !important;
            font-size: ${settingsStyled.formFontSize};
            line-height: ${settingsStyled.formHeight};
          }

          &-placeholder {
            font-size: ${settingsStyled.formFontSize};
            display: block;
            padding-left: 12px !important;
          }
          &-item {
            padding: 4px 12px !important;
            ${settingsStyled.displayCenter};
            font-weight: normal;
            color: ${settingsStyled.formFontColor};
            font-size: ${settingsStyled.formFontSize};
          }
        }
        &-selector {
          padding: 0 11px 0 0 !important;
          border-radius: ${settingsStyled.formRadius} !important;
          border: solid 1px ${settingsStyled.formBorder} !important;
          outline: none;
          box-shadow: none !important;
          background-color: ${settingsStyled.formBg} !important;
          overflow: hidden !important;
        }
      }
      &.ant-select-disabled {
        .ant-select-selector {
          background: #f7f6f6 !important;
        }
        &:hover {
          .ant-select-selector {
            background: #fff !important;
          }
        }
        .ant-select-selection-item {
          color: rgba(0, 0, 0, 0.25);
        }
      }
    }
  }

  &.formSelectBox-multiple {
    .ant-select-selection {
      &-placeholder {
        padding-left: 0 !important;
      }
      &-item {
        margin: 4px;
        border: 1px solid ${settingsStyled.formBorder};
        border-radius: 4px;
        display: flex;
        max-width: 93%;

        &-content {
          color: ${settingsStyled.formFontColor};
          letter-spacing: 0.3px;
          font-size: 13px;
        }
      }
    }

    svg {
      font-size: 11px;
    }
  }
`;
