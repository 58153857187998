import React, { memo, useCallback } from "react";
import { Form, Switch } from "antd";
import FormElementLabel from "./formElementLabel";
import PropTypes from "prop-types";
import getWordControl from "../../utils/language/getWordControl";
import { FormSwitchStyled } from "./styled/formSwitch.styled";

// <FormSwitch
//   className=""
//   label="switch"
//   requiredStatus={true}
//   propName="swxitch"
//   errorMessage="msg"
//   disabled={true}
//   switchOnChange={func}
// />

const FormSwitch = memo((props) => {
  const {
    className,
    requiredStatus,
    label,
    propName,
    errorMessage,
    disabled,
    switchOnChange,
  } = props;
  const { GetWord } = getWordControl();

  const onChange = useCallback(
    (value) => {
      if (switchOnChange) {
        switchOnChange(value, propName);
      }
    },
    [propName, switchOnChange]
  );

  return (
    <FormSwitchStyled className={"formSwitch " + (className ? className : "")}>
      <FormElementLabel labelName={label} requiredStatus={requiredStatus} />
      <Form.Item
        name={propName}
        valuePropName="checked"
        rules={
          requiredStatus
            ? [
                {
                  validator: (_, value) => {
                    return value
                      ? Promise.resolve()
                      : Promise.reject(
                          errorMessage
                            ? GetWord(errorMessage)
                            : GetWord("pleaseFillRequiredFields")
                        );
                  },
                },
              ]
            : null
        }
      >
        <Switch disabled={disabled} onChange={onChange} />
      </Form.Item>
    </FormSwitchStyled>
  );
});

FormSwitch.propTypes = {
  propName: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  requiredStatus: PropTypes.bool,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  switchOnChange: PropTypes.func,
};

export default FormSwitch;
