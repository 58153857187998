import styled from "styled-components";
import settingsStyled from "../../../../Assets/styled/settings.styled";

export const FormSwitchStyled = styled.div`
  .ant-switch-checked {
    background-color: ${settingsStyled.formSwitchBg};
  }

  .formSwitch {
  }
`;
