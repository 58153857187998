import styled from "styled-components";
import settingsStyled from "../../../../Assets/styled/settings.styled";

export const FormCheckBoxStyled = styled.div`
  .ant {
    &-checkbox-wrapper {
      ${settingsStyled.displayCenter}
      margin-bottom: 20px;
      .ant-checkbox-inner {
        width: 24px;
        height: 24px;
        border-radius: 5px;
        border: 1px solid #0B4173;
        z-index: 1;
        background-color: white;
        &:hover,
        &:focus {
          /* border: solid 1px ${settingsStyled.formBorderFocus} !important; */
        }

        &::after {
          border-color: #00384d;
        }
      }

      .ant-checkbox {
        background-color: #f4f8f8;
        &::after {
          border: none !important;
        }
      }

      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: white;
        &::after {
          left: 25%;
          height: 13px;
          width: 7px;
          top: 45%;
        }
      }
    }
  }

  .formCheckBox {
    &__label {
      font-weight: 500;
      font-size: 18px;
      color: ${settingsStyled.formLabelColor};
      line-height: 1.64;
      display: block;
      padding-top: 5px;
      padding-left: 4px;

      &.required {
        &:after {
          content: " *";
          color: ${settingsStyled.errorRed};
        }
      }
    }

    &-group {
      ${settingsStyled.displayColumn};
      .ant-checkbox-wrapper {
        margin-left: 0;
        margin-bottom: 18px;

        &-checked {
          .formCheckBox__label {
            font-weight: bold;
          }
        }
      }
    }

    &-search {
      position: relative;
      .formTextInput {
        input {
          height: 40px;
          padding-right: 35px;
          &::placeholder {
            color: #000;
            opacity: 0.5;
            font-size: 12px;
          }
        }
      }
      .icon {
        position: absolute;
        right: 16px;
        top: 19px;
        width: 16px;
        height: 16px;
        color: #416a7a;
      }
    }

    /* contract */
    &-contractActive {
      text-decoration: underline;
    }
  }
`;
