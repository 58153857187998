import React, { memo } from "react";
import { Form, Button } from "antd";
import Loading from "../loading/loading";
import PropTypes from "prop-types";
import Icon from "../icons/icon";
import getWordControl from "../../utils/language/getWordControl";
import { FormSubmitButtonStyled } from "./styled/formSubmitButton.styled";

//<FormSubmitButton
//  label="SUBMIT"
//  className=""
//  textColor=""
//  backgroundColor =""
//  loadingActive={true}
//  disabled={true}
//  width={"100%"}
//  labelGetwordClose={false}
//  style={{}}
//  widthAutoActive={true} // full width kapatıyor
//  iconPosition="left" /"right"
//  iconName=""
//  btnColor="redBtn" / "angularBlue"
// />

const FormSubmitButton = memo(
  ({
    label,
    backgroundColor,
    className,
    loadingActive,
    textColor,
    disabled,
    width,
    labelGetwordClose,
    style,
    iconPosition,
    btnColor,
    iconName,
    widthAutoActive,
  }) => {
    const { GetWord } = getWordControl();

    return (
      <FormSubmitButtonStyled
        className={
          "formSubmitButton " +
          (widthAutoActive ? "formSubmitButton--widthAutoActive " : "") +
          (className || "")
        }
        style={{ width }}
      >
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ backgroundColor, ...style }}
            disabled={loadingActive || disabled}
            className={"ant-" + btnColor}
          >
            {iconPosition === "left" && iconName && (
              <Icon
                name={iconName}
                className="formSubmitButton--iconLeft"
                classNameOut="formSubmitButton-iconOut"
              />
            )}

            <span style={{ color: textColor }}>
              {label ? (!labelGetwordClose ? GetWord(label) : label) : ""}
            </span>
            {iconPosition === "right" && iconName && (
              <Icon
                name={iconName}
                className="formSubmitButton--iconRight"
                classNameOut="formSubmitButton-iconOut"
              />
            )}

            {loadingActive && <Loading size="small" innerPageActive={true} />}
          </Button>
        </Form.Item>
      </FormSubmitButtonStyled>
    );
  }
);

FormSubmitButton.propTypes = {
  label: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  loadingActive: PropTypes.bool,
  textColor: PropTypes.string,
  disabled: PropTypes.bool,
  btnColor: PropTypes.string,
  labelGetwordClose: PropTypes.bool,
  style: PropTypes.object,
  width: PropTypes.node,
};

export default FormSubmitButton;
