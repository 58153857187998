import styled from "styled-components";
import settingsStyled from "../../../../Assets/styled/settings.styled";

export const FormTimePickerStyled = styled.div`
  width: 128px;

  .ant-picker-focused,
  .ant-picker:hover {
    box-shadow: none;
  }

  .formTimePicker {
    &__datePicker {
      margin-top: 6px;
      height: ${settingsStyled.formHeight};
      border-radius: ${settingsStyled.formRadius};
      border: solid 1px ${settingsStyled.formBorder};
      background-color: ${settingsStyled.formBg};
      padding: 0 21px;
      color: ${settingsStyled.formFontColor};
      line-height: ${settingsStyled.formHeight};
      font-size: ${settingsStyled.formFontSize};
      &:focus {
        border: solid 1px ${settingsStyled.formBorderFocus};
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
      }

      &:hover {
        border: solid 1px ${settingsStyled.formBorderFocus};
      }

      input {
        color: ${settingsStyled.formFontColor};
        line-height: 1.44;
        font-weight: 400;
        font-size: ${settingsStyled.formFontSize};
      }
    }
  }
`;
