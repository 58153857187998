import React, { memo, useCallback, useMemo, useEffect, useState } from "react";
import { Form, Select, Empty } from "antd";
import PropTypes from "prop-types";
import { monthsSelectBoxGetword } from "../../utils/datas/monthSelectBox";
import { findData, yearSelectBox } from "../../utils/mixedControl";
import getWordControl from "../../utils/language/getWordControl";
import { FormSelectBoxStyled } from "./styled/formSelectBox.styled";
import FormElementLabel from "./formElementLabel";
import FormTextInput from "./formTextInput";

const turkishToLower = (word) => {
  const letters = { İ: "i", I: "ı", Ş: "ş", Ğ: "ğ", Ü: "ü", Ö: "ö", Ç: "ç" };
  return word.replace(/([İIŞĞÜÇÖ])/g, (letter) => letters[letter]).toLowerCase();
};

const FormSelectBox = memo((props) => {
  const {
    propName,
    label,
    requiredStatus,
    className,
    errorMessage,
    otherStatus,
    form,
    disabled,
    mountDataActive,
    yearActice,
    mode,
    showSearch,
    selectBoxOnChange,
    placeholder,
    fullDataReturn,
    allowClear,
    otherPropName,
    selectData,
    onScroll,
    filterControl = showSearch
      ? (input, option) =>
          turkishToLower(option?.children?.toString()).indexOf(
            turkishToLower(input?.toString())
          ) >= 0
      : null,
  } = props;

  const { GetWord } = getWordControl();

  const selectboxDataControl = useCallback(() => {
    if (mountDataActive) {
      return monthsSelectBoxGetword;
    } else if (yearActice) {
      return yearSelectBox(yearActice);
    } else {
      return [];
    }
  }, [mountDataActive, yearActice]);

  const handleChange = useCallback(
    (value) => {
      if (otherStatus) {
        form.setFieldsValue();
      }
      if (selectBoxOnChange) {
        const selectFullData = fullDataReturn
          ? findData(selectboxDataControl(), "id", value)
          : null;
        selectBoxOnChange(value, propName, selectFullData);
      }
    },
    [
      propName,
      selectBoxOnChange,
      otherStatus,
      form,
      fullDataReturn,
      selectboxDataControl,
    ]
  );

  const selectDataControl = useMemo(() => {
    return selectData?.map((element, index) => (
      <Select.Option key={index} value={element.id}>
        {element.name}
      </Select.Option>
    ));
  }, [selectData]);

  return (
    <FormSelectBoxStyled
      className={
        "formSelectBox " +
        (mode === "multiple" ? " formSelectBox-multiple " : "") +
        (className ? className : "")
      }
    >
      <FormElementLabel labelName={label} requiredStatus={requiredStatus} />
      <Form.Item
        name={propName}
        rules={
          requiredStatus
            ? [
                {
                  validator: (_, value) => {
                    return value
                      ? Promise.resolve()
                      : Promise.reject(
                          errorMessage
                            ? GetWord(errorMessage)
                            : GetWord("pleaseFillRequiredFields")
                        );
                  },
                },
              ]
            : null
        }
      >
        <Select
          className={propName + " formSelectBox__select "}
          onChange={handleChange}
          disabled={disabled}
          mode={mode || ""}
          showSearch={showSearch}
          showArrow
          allowClear={allowClear}
          placeholder={GetWord(placeholder)}
          notFoundContent={
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={GetWord("lblNoData")}
            />
          }
          filterOption={filterControl}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          onPopupScroll={onScroll}
        >
          {selectDataControl}
        </Select>
      </Form.Item>

      {otherStatus && (
        <Form.Item
          shouldUpdate={(prevValues, currentValues) =>
            prevValues[propName] !== currentValues[propName]
          }
        >
          {({ getFieldValue }) => {
            return getFieldValue(propName) === "other" ? (
              <FormTextInput
                label={label + "Other"}
                propName={otherPropName}
                requiredStatus={requiredStatus}
              />
            ) : null;
          }}
        </Form.Item>
      )}
    </FormSelectBoxStyled>
  );
});

FormSelectBox.propTypes = {
  propName: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  requiredStatus: PropTypes.bool,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  otherStatus: PropTypes.bool,
  mountDataActive: PropTypes.bool,
  yearActice: PropTypes.number,
  mode: PropTypes.string,
  showSearch: PropTypes.bool,
  selectBoxOnChange: PropTypes.func,
  fullDataReturn: PropTypes.bool,
  allowClear: PropTypes.bool,
  otherPropName: PropTypes.string,
  visibleData: PropTypes.array,
  onScroll: PropTypes.func,
};

export default FormSelectBox;
