import styled from "styled-components";
import settingsStyled from "../../../../Assets/styled/settings.styled";

export const FormTextInputStyled = styled.div`
  width: 100%;

  .ant-input-password {
    box-shadow: none;
  }
  /* text input */
  .formTextInput {
    &-inner {
      display: flex;
      width: 100%;

      .ant-form-item {
        width: 100%;
      }
    }

    &__input {
      border: solid 1px ${settingsStyled.formBorder};
      background-color: ${settingsStyled.formBg} !important;
      color: ${settingsStyled.formFontColor};
      line-height: ${settingsStyled.formHeight};
      font-size: ${settingsStyled.formFontSize};
      height: ${settingsStyled.formHeight};
      margin-top: 6px;
      border-radius: ${settingsStyled.formRadius};
      padding: 0 21px;

      &:focus {
        border: solid 1px ${settingsStyled.formBorderFocus} !important;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08) !important;
      }

      &:hover {
        border: solid 1px ${settingsStyled.formBorderFocus} !important;
      }
    }
  }

  /* passwordinput */
  &.formTextInput--password {
    input {
      color: ${settingsStyled.formFontColor};
      line-height: 1.44;
      font-weight: 500;
      font-size: ${settingsStyled.formFontSize};
      background-color: ${settingsStyled.formBg} !important;
      border-radius: 10px;
    }

    .formTextInput__input {
      padding-top: 0;
    }

    .ant-input-suffix {
      svg {
        font-size: 20px;
      }
    }
  }
`;
