import React, { memo, useCallback, useMemo } from "react";
import { Radio, Form } from "antd";
import FormTextInput from "./formTextInput";
import FormElementLabel from "./formElementLabel";
import PropTypes from "prop-types";
import { FormRadioGroupStyled } from "./styled/formRadioGroup.styled";
import getWordControl from "../../utils/language/getWordControl";

//<FormRadioGroup
//label="RadioGroup (Test)"
//radioData={radioData}
//propName="radioGroup"
//requiredStatus={true}
//errorMessage="lbl"
//radioGroupOnChange={func}
//className=""
//otherStatus={false},
//form={form},
//type="stringButton"
///>

// other için data içerisinde
//{ id: "other", name: "other" }

const FormRadioGroup = memo((props) => {
  const {
    propName,
    radioData,
    label,
    requiredStatus,
    className,
    errorMessage,
    radioGroupOnChange,
    otherStatus,
    form,
    type,
  } = props;

  const { GetWord } = getWordControl();
  const handleChange = useCallback(
    (e) => {
      const value = e.target.value;

      if (otherStatus) {
        form.setFieldsValue();
      }
      if (radioGroupOnChange) {
        radioGroupOnChange(value, propName);
      }
    },
    [otherStatus, radioGroupOnChange, propName, form]
  );

  const radioDataControl = useMemo(() => {
    return radioData.map((element, index) => {
      return (
        <Radio key={index} value={element.id}>
          <span className="formRadioGroup-item">{element.name}</span>
          {type === "stringButton" && (
            <span className="formRadioGroup-count">{element.count}</span>
          )}
        </Radio>
      );
    });
  }, [radioData, type]);

  return (
    <FormRadioGroupStyled
      className={
        "formRadioGroup " +
        (type === "stringButton" ? " formRadioGroup--stringButton " : "") +
        (className || "")
      }
    >
      <FormElementLabel labelName={label} requiredStatus={requiredStatus} />
      <Form.Item
        name={propName}
        rules={
          requiredStatus
            ? [
                {
                  validator: (_, value) => {
                    return value
                      ? Promise.resolve()
                      : Promise.reject(
                          errorMessage
                            ? GetWord(errorMessage)
                            : GetWord("pleaseFillRequiredFields")
                        );
                  },
                },
              ]
            : null
        }
      >
        <Radio.Group
          className={propName + " formRadioGroup__radioGroup "}
          onChange={handleChange}
        >
          {radioDataControl}
        </Radio.Group>
      </Form.Item>

      {otherStatus && (
        <Form.Item
          shouldUpdate={(prevValues, currentValues) =>
            prevValues[propName] !== currentValues[propName]
          }
        >
          {({ getFieldValue }) => {
            return getFieldValue(propName) === "other" ? (
              <FormTextInput
                label={""}
                propName={propName + "Other"}
                requiredStatus={requiredStatus}
              />
            ) : null;
          }}
        </Form.Item>
      )}
    </FormRadioGroupStyled>
  );
});

FormRadioGroup.propTypes = {
  propName: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  requiredStatus: PropTypes.bool,
  errorMessage: PropTypes.string,
  radioData: PropTypes.array,
  radioGroupOnChange: PropTypes.func,
  otherStatus: PropTypes.bool,
};

export default FormRadioGroup;
