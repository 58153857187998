import React, { memo, useCallback, useMemo, useState } from "react";
import { Form } from "antd";
import MaskedInput from "react-text-mask";
import FormElementLabel from "./formElementLabel";
import PropTypes from "prop-types";
import { FormMaskStyled } from "./styled/formMask.styled";
import getWordControl from "../../utils/language/getWordControl";

//<Form
//initialValues={{
//    propName: "05397858533",
//    Yarım değer girilince * ekle sonuna validasyon için
//    propName: "0*",
//    propName: "05*",
//}}
//></Form>

//<FormMask
//label="PhoneNumber"
//propName="phoneNumber"
//requiredStatus={true}
//errorMessage="pleaseFillRequiredFields"
//format="phoneNumber" // "vkn" / "tc"/ "tcVkn / "cvc" / "cardNumber" , "expiryDate""
//placeholder="0(5__)___-____"
//className=""
// form={form}
// maskOnChange={func}
///>

//Value form'da gönderirken ()- silinebilir
//values.replace(/[^0-9]+/g, "")

const FormMask = memo(
  ({
    propName,
    label,
    requiredStatus,
    placeholder,
    className,
    errorMessage,
    format,
    maskOnChange,
    form,
    onMouseOver,
    onMouseLeave,
  }) => {
    const [focusedOut, setFocusedOut] = useState(false);
    const { GetWord } = getWordControl();
    const onChange = useCallback(
      (e) => {
        const _value = e.target.value;
        const value = e.target.value.replace(/[^0-9]+/g, "");

        if (maskOnChange) {
          maskOnChange(value, propName);
        }

        if (format === "iban" && !_value) {
          form.setFieldsValue({
            [propName]: "TR",
          });
        }
      },
      [propName, maskOnChange, form, format]
    );

    const maskFormatControl = useMemo(() => {
      if (format === "phoneNumber") {
        return [
          "(",
          /[5]/,
          /[0-9]/,
          /[0-9]/,
          ")",
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          "-",
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
        ];
      } else if (format === "tcVkn" || format === "tc") {
        return [
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
        ];
      } else if (format === "vkn") {
        return [
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
        ];
      } else if (format === "iban") {
        return [
          /[T]/,
          /[R]/,
          /[0-9]/,
          /[0-9]/,
          " ",
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          " ",
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          " ",
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          " ",
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          " ",
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          " ",
          /[0-9]/,
          /[0-9]/,
        ];
      } else if (format === "cardNumber") {
        return [
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          " ",
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          " ",
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          " ",
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
        ];
      } else if (format === "cvc") {
        return [/[0-9]/, /[0-9]/, /[0-9]/];
      } else if (format === "expiryDate") {
        return [/[0-9]/, /[0-9]/, "/", /[0-9]/, /[0-9]/];
      } else {
        return "";
      }
    }, [format]);

    const rulesControl = useMemo(() => {
      if (format === "phoneNumber") {
        return requiredStatus
          ? [
              {
                validator: (_, value) =>
                  value && !focusedOut && !value.includes("*")
                    ? Promise.resolve()
                    : value &&
                      focusedOut &&
                      ((!value.includes("(") && value.length === 10) ||
                        (!value.includes("_") && value.length === 13))
                    ? Promise.resolve()
                    : Promise.reject(
                        errorMessage
                          ? GetWord(errorMessage)
                          : GetWord("pleaseFillRequiredFields")
                      ),
              },
            ]
          : null;
      } else if (format === "tcVkn") {
        return requiredStatus
          ? [
              {
                validator: (_, value) => {
                  if (value && (value.length === 32 || value.length === 10)) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(
                      errorMessage
                        ? GetWord(errorMessage)
                        : GetWord("pleaseFillRequiredFields")
                    );
                  }
                },
              },
            ]
          : null;
      } else if (format === "vkn") {
        return requiredStatus
          ? [
              {
                validator: (_, value) => {
                  if (value && value.length === 10) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(
                      errorMessage
                        ? GetWord(errorMessage)
                        : GetWord("pleaseFillRequiredFields")
                    );
                  }
                },
              },
            ]
          : null;
      } else if (format === "tc") {
        return requiredStatus
          ? [
              {
                validator: (_, value) => {
                  if (value && value.length === 11) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(
                      errorMessage
                        ? GetWord(errorMessage)
                        : GetWord("pleaseFillRequiredFields")
                    );
                  }
                },
              },
            ]
          : null;
      } else if (format === "iban") {
        return requiredStatus
          ? [
              {
                validator: (_, value) => {
                  if (value && value.length === 32) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(
                      errorMessage
                        ? GetWord(errorMessage)
                        : GetWord("pleaseFillRequiredFields")
                    );
                  }
                },
              },
            ]
          : null;
      } else if (format === "cardNumber") {
        return requiredStatus
          ? [
              {
                validator: (_, value) =>
                  value && !focusedOut && !value.includes("*")
                    ? Promise.resolve()
                    : value &&
                      focusedOut &&
                      !value.includes("_") &&
                      value.length === 19
                    ? Promise.resolve()
                    : Promise.reject(
                        errorMessage
                          ? GetWord(errorMessage)
                          : GetWord("pleaseFillRequiredFields")
                      ),
              },
            ]
          : null;
      } else if (format === "cvc") {
        return requiredStatus
          ? [
              {
                validator: (_, value) =>
                  value && !focusedOut && !value.includes("*")
                    ? Promise.resolve()
                    : value &&
                      focusedOut &&
                      !value.includes("_") &&
                      value.length === 3
                    ? Promise.resolve()
                    : Promise.reject(
                        errorMessage
                          ? GetWord(errorMessage)
                          : GetWord("pleaseFillRequiredFields")
                      ),
              },
            ]
          : null;
      } else if (format === "expiryDate") {
        return requiredStatus
          ? [
              {
                validator: (_, value) =>
                  value && !focusedOut && !value.includes("*")
                    ? Promise.resolve()
                    : value &&
                      focusedOut &&
                      !value.includes("_") &&
                      value.length === 5
                    ? Promise.resolve()
                    : Promise.reject(
                        errorMessage
                          ? GetWord(errorMessage)
                          : GetWord("pleaseFillRequiredFields")
                      ),
              },
            ]
          : null;
      }

      return null;
    }, [errorMessage, requiredStatus, focusedOut, format]);

    return (
      <FormMaskStyled className={"formMask " + (className || "")}>
        <FormElementLabel labelName={label} requiredStatus={requiredStatus} />

        <Form.Item name={propName} rules={rulesControl}>
          <MaskedInput
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            onBlur={() => {
              setFocusedOut(true);
            }}
            onFocus={() => {
              setFocusedOut(false);
            }}
            mask={maskFormatControl}
            className={propName + " formMask__input "}
            placeholder={placeholder || ""}
            guide={
              format !== "tcVkn" &&
              format !== "tc" &&
              format !== "vkn" &&
              format !== "iban"
            }
            id={propName}
            onChange={onChange}
            type={
              format === "tcVkn" ||
              format === "tc" ||
              format === "vkn" ||
              format === "phoneNumber" ||
              format === "cardNumber" ||
              format === "cvc" ||
              format === "expiryDate" ||
              format === "iban"
                ? "tel"
                : undefined
            }
          />
        </Form.Item>
      </FormMaskStyled>
    );
  }
);

FormMask.propTypes = {
  propName: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  requiredStatus: PropTypes.bool,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  format: PropTypes.string,
  maskOnChange: PropTypes.func,
};

export default FormMask;
