export const monthsSelectBoxGetword = [
  // { id: 1, name: GetWord("January") },
  // { id: 2, name: GetWord("February") },
  // { id: 3, name: GetWord("March") },
  // { id: 4, name: GetWord("April") },
  // { id: 5, name: GetWord("May") },
  // { id: 6, name: GetWord("June") },
  // { id: 7, name: GetWord("July") },
  // { id: 8, name: GetWord("August") },
  // { id: 9, name: GetWord("September") },
  // { id: 10, name: GetWord("October") },
  // { id: 11, name: GetWord("November") },
  // { id: 12, name: GetWord("December") },
];
