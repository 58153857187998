import styled from "styled-components";
import settingsStyled from "../../../../Assets/styled/settings.styled";

export const FormTextAreaStyled = styled.div`
  width: 100%;
  .formTextArea {
    &__textarea {
      ${settingsStyled.scrollYBlue}

      resize: none;
      height: ${settingsStyled.formTextAreaHeight} !important;
      margin-top: 6px;
      border-radius: 4px;
      border: solid 1px ${settingsStyled.formBorder};
      background-color: ${settingsStyled.formBg} !important;
      padding: 18px 20px;
      color: ${settingsStyled.formFontColor};
      line-height: 1.44;
      font-size: ${settingsStyled.formFontSize};

      &:focus {
        border: solid 1px ${settingsStyled.formBorderFocus};
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
      }

      &:hover {
        border: solid 1px ${settingsStyled.formBorderFocus};
      }
    }
  }
`;
