import React, { memo, useCallback } from "react";
import { Form, Input } from "antd";
import FormElementLabel from "./formElementLabel";
import PropTypes from "prop-types";
import getWordControl from "../../utils/language/getWordControl";
import { FormTextAreaStyled } from "./styled/formTextArea.styled";

//<FormTextArea
// label="textArea"
// propName="textArea"
// requiredStatus={true}
// placeholder="textArea"
// className="textArea"
// errorMessage="errorMsg"
// disabled={true}
// />

const FormTextArea = memo((props) => {
  const {
    propName,
    label,
    requiredStatus,
    placeholder,
    className,
    errorMessage,
    disabled,
    textAreaOnBlur,
    textAreaOnChange,
  } = props;
  const { GetWord } = getWordControl();
  const onBlur = useCallback(
    (e) => {
      if (textAreaOnBlur) {
        textAreaOnBlur(e.target.value, propName);
      }
    },
    [propName, textAreaOnBlur]
  );

  const onChange = useCallback(
    (e) => {
      if (textAreaOnChange) {
        textAreaOnChange(e.target.value, propName);
      }
    },
    [propName, textAreaOnChange]
  );

  return (
    <FormTextAreaStyled className={"formTextArea " + (className ? className : "")}>
      <FormElementLabel labelName={label} requiredStatus={requiredStatus} />
      <Form.Item
        name={propName}
        rules={[
          {
            required: requiredStatus,
            message: errorMessage
              ? GetWord(errorMessage)
              : GetWord("pleaseFillRequiredFields"),
          },
        ]}
      >
        <Input.TextArea
          className={propName + " formTextArea__textarea "}
          placeholder={placeholder ? GetWord(placeholder) : ""}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
        />
      </Form.Item>
    </FormTextAreaStyled>
  );
});

FormTextArea.propTypes = {
  propName: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  requiredStatus: PropTypes.bool,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  textAreaOnBlur: PropTypes.func,
  textAreaOnChange: PropTypes.func,
};

export default FormTextArea;
