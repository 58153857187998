import styled from "styled-components";
import settingsStyled from "../../../../Assets/styled/settings.styled";

export const FormListInputsStyled = styled.span`
  width: 100%;

  .formListInputs {
    &--label {
      font-size: 16px;
      margin-bottom: 15px !important;
    }

    &-list {
      > div {
        &:last-child {
          margin-top: 6px;
          margin-bottom: 24px;
          height: 32px;

          .ant-form-item-control-input,
          .ant-form-item-control-input-content,
          button {
            border-radius: 4px;
          }
          button {
            padding-top: 6px;
            color: ${settingsStyled.formFontColor};
            span {
              font-size: ${settingsStyled.formFontSize};
            }
            &:hover,
            &:focus {
              color: ${settingsStyled.formFontColor};
              border-color: #000;
            }
          }
        }
      }

      .formTextInput,
      .formInputNumber,
      .formDatePicker {
        margin-right: 10px;
      }

      &-item {
        ${settingsStyled.displayBetweenCenter};

        &-close {
          height: 22px;
          .icon {
            cursor: pointer;
            color: ${settingsStyled.errorRed};
            width: 35px;
          }
        }
      }
    }
  }
`;
